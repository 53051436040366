#messages_desktop_wrapper {
  padding: 20px 20px 0px 20px;

  #recent-list.scrollbar-container {
    @media screen and (min-width: 768px) {
      height: 410px;
    }
  }

  .chat-conversation {
    .chat-item {
      clear: both;
    }
  }

  .chat-leftsidebar {
    max-width: 380px;
  }

  .clear-float {
    clear: both;
  }

  .dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $primary;
    color: $primary;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $primary;
    color: $primary;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $primary;
    color: $primary;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: $primary;
    }

    50%,
    100% {
      background-color: rgba(85, 110, 230, 0.2);
    }
  }
}

#desktop_chat_modal {
  .modal-content {
    background-color: $body-bg;
  }

  .modal-header {
    padding: 16px 60px 16px 50px;

    .close-btn {
      height: 100%;

      i {
        font-size: 32px;
      }
    }
  }
}