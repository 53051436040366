@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// // RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

.btn-primary {
  padding: 0.7rem 0.75rem;
  font-family: "Poppins"
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}

svg>rect:first-child {
  fill-opacity: 0 !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}

.dz-message {
  text-align: center;
  padding: 30px;
}

// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}

.task-box {
  border: 1px solid $border-color;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

a:hover {
  cursor: pointer;
}

.rating-container {
  background-color: transparent !important;
}

.input-group-append {
  z-index: 0;
}

.input-color {
  color: $input-color !important;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}

.flatpickr-weekdays {
  background: $primary !important;
}

span.flatpickr-weekday {
  background: $primary !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}

span.flatpickr-weekday {
  color: $white !important;
}

.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}

.carousel {
  .control-dots {
    margin: -5px 0px;
  }

  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;

    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }

  .fc {
    .fc-toolbar {
      >* {
        >* {
          float: none;
        }
      }
    }
  }

  .fc-today-button {
    display: none;
  }
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}

.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;

  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}

.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}

.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}

.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.ReactModal__Overlay {
  z-index: 5000 !important;
}

.rangeslider__handle:focus {
  outline: none;
}

// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}

.search-label {
  float: right;
}

.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}

.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}

.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

// .accordion-header {
//   color: #495057 !important;
//   background-color: transparent !important;

//   .active {
//     color: #4d63cf !important;
//     background-color: #eef1fd !important;
//   }
// }

/***** datatables *****/

.react-bootstrap-table {
  thead th {
    cursor: pointer;
  }

  .dropup>.caret {
    margin: 10px 0;
    color: #cccccc;
  }

  .dropup .caret,
  .navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
  }

  th>.react-bootstrap-table-sort-order>.caret {
    margin: 10px 6.5px;
  }
}

.react-bootstrap-table,
.inner-custom-pagination {
  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

.spinner-chase.sm {
  width: 32px;
  height: 32px;
}

.spinner-chase.dtable {
  // datatable row height - spinner height
  margin-bottom: calc(44.5px - 32px);
}

.dtable-icon {
  font-size: 20px;
  display: inline-block;
  margin-top: -8px;
  margin-bottom: -8px;
}

.search-box .form-control {
  border-radius: 0.25rem;
}

.dtable-filters-column {
  width: 330px;
}

.close-dtable-filters-btn {
  position: absolute;
  top: 1px;
  right: 3px;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.is-invalid .select2-selection__control {
  border-color: #f34943 !important;
}

.react-bs-table-no-data {
  text-align: center;
  border-bottom: none;
}

.card-header:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}

#preloader.inner {
  background-color: transparent;
  left: 250px; // width of vertical-menu
}

.rnc__notification-item--success {
  background-color: #34c38f;
  border-left: 8px solid #2a9c72;

  .rnc__notification-close-mark,
  .rnc__notification-timer {
    background-color: #34c38f;
  }
}

.rnc__notification-item--info {
  background-color: #50a5f1;
  border-left: 8px solid #4084c1;

  .rnc__notification-close-mark,
  .rnc__notification-timer {
    background-color: #50a5f1;
  }
}

.rnc__notification-item--warning {
  background-color: #f1b44c;
  border-left: 8px solid #c1903d;

  .rnc__notification-close-mark,
  .rnc__notification-timer {
    background-color: #f1b44c;
  }
}

.rnc__notification-item--danger {
  background-color: #f46a6a;
  border-left: 8px solid #c35555;

  .rnc__notification-close-mark,
  .rnc__notification-timer {
    background-color: #f46a6a;
  }
}

.main-content {
  min-height: 100vh;
}

.card.expand-v {
  height: calc(100% - 24px);
}

// Start profile dropzone

.profile-dropzone {
  height: 100%;

  .dz-message {
    height: 100%;
    cursor: pointer;
  }

  .dz-message-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.profile-dropzone-preview {
  width: 70%;
  margin: 0 auto;
  position: relative;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.profile-dropzone-preview-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  border: 15px solid #f5f4f8;

  &:not(.is-busy):hover {
    .profile-dropzone-preview-bg {
      transform: scale(1.05);
      filter: blur(8px);
    }

    .profile-dropzone-clear {
      display: block;
    }
  }

  &.is-busy {
    .profile-dropzone-preview-bg {
      transform: scale(1.05);
      filter: blur(8px);
    }

    .profile-dropzone-busy {
      display: flex;
    }
  }
}

.profile-dropzone-preview-bg {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.profile-dropzone-clear {
  border: none;
  padding: 0;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.profile-dropzone-busy {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;

  .chase-dot:before {
    background-color: #ffffff;
  }
}

// End profile dropzone

// Start user avatar

.user-avatar {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  box-sizing: content-box;

  &.user-avatar-sm {
    width: 33px;
    border: 3px solid #f5f4f8;

    &.in-table {
      margin-top: -10px;
      margin-bottom: -10px;
    }

    .user-avatar-bg.no-avatar {
      font-size: 18px;
    }
  }

  &.user-avatar-md {
    width: 48px;
  }

  &.user-avatar-lg {
    width: 64px;
  }

  &.user-avatar-xl {
    width: 108px;
  }

  &.user-avatar-xxl {
    width: 200px;
    border: 15px solid #f5f4f8;

    .user-avatar-bg.no-avatar {
      font-size: 100px;
    }
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.user-avatar-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.no-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    background-color: rgb(219, 222, 227);

    color: #fff;
  }
}

// End user avatar

// Start impersonation alert

.impersonation-alert {
  font-size: 30px;
  display: inline-flex;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $danger;
    animation-name: pulse;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $red-500;
    animation-name: pulse2;
    animation-duration: 2s;
    animation-delay: 0.4s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  75% {
    transform: scale(3.5);
    opacity: 0;
  }

  100% {
    transform: scale(3.5);
    opacity: 0;
  }
}

@keyframes pulse2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(4);
    opacity: 0;
  }
}

// End impersonation alert

.btn-faded {
  opacity: 0.65;
}

// Text align

.align-justify {
  text-align: justify;
}

@import "./mav.scss";