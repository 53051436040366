#vid_mobile_wrapper {
  .full-height-column {
    height: calc(100vh - 145px);
  }

  .vid-column {
    padding: 0 25px;
  }

  // Welcome component
  #id_welcome {
    .welcome-title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #2c3746;
    }
  }

  // Consent component
  #id_instructions,
  #id_options {
    .confirm-title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #2c3746;
    }

    .consent-wrapper {
      display: flex;
      align-items: center;
      margin-top: 10px;
      padding: 0 10px;

      .consent-checkbox {
        margin-top: 1px;
      }

      #consentClick {
        margin-left: 10px;
        font-size: 14px;
        color: #57585b;
        letter-spacing: 0.097875px;
        background: none;
        border: none;
        text-align: start;
        font-family: Poppins
      }
    }
  }

  #id_instructions {
    .consent-signer-name {
      color: #556EE6;
      font-size: 14px;
      font-weight: 500;
      font-family: "Poppins";
      border: none;
      border-bottom: 2px solid #556EE6;
      border-radius: 0;
    }

    .consent-signer-name::placeholder {
      color: #556EE6;
      font-weight: 500;
      font-size: 14px
    }
  }

  #id_consent {
    font-family: "Poppins";
    font-style: normal;
    color: #2c3746;
    background: white;
    top: 56px;
    position: absolute;
    z-index: 99;

    .exit-consent {
      background: none;
      border: none;
      width: 30px;
      font-size: 20px;
      padding: 0;
    }

    h3 {
      font-family: "Poppins";
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.0807857px;
      color: #2c3746;
    }

    p {
      font-family: "Poppins";
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 9px;
      color: #2C3746;
    }

    .footer-info {
      font-size: 12px;
      line-height: 22px;
      letter-spacing: 0.0923265px;

      sup {
        margin-right: 3px;
      }
    }

    .consent-actions {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 80px;

      .btn-primary {
        padding: 0.47rem 0.75rem;
        background-color: #556EE6;
        font-size: 12px;
        font-weight: 400;
        font-family: "Poppins"
      }
    }
  }

  // Vid complete component
  .upload-progress-bar {
    width: 90%;
    display: inline-flex;
  }

  // DL and Passport instructions components
  .id-instructions {

    .card-body {
      padding-left: 10px;
      padding-right: 10px;
    }

    #actionsRow {
      display: flex;
      justify-content: space-between;

      .back-btn {
        border: none;
        background: none;
        padding: 0;

        i {
          color: #2c3746;
          font-size: 20px;
        }
      }

      .tips-btn {
        box-shadow: none;
        border-radius: 4px;
        padding: 6px 8px;
        color: #222222;
        background: #CED4DA;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;

        &.active {
          color: #fff;
          background-color: #556EE6;
          border-color: #556EE6;
        }
      }
    }

    .instructions-wrapper {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #444444;
      padding-bottom: 95px;

      .instructions-label {
        color: #2C3746;
        margin-top: 20px;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }
    }

    .instr-i {
      color: #00b214;
      font-size: 15px;
    }

    .preview-row {
      display: flex;
      justify-content: space-between;

      .small-preview {
        flex-basis: 31%;

        .image-wrapper {
          height: 100px;
          width: 100%;
          margin-bottom: 5px;
          border-radius: 5px;
          overflow: hidden;

          img {
            width: 100%;
            margin-top: 50%;
            transform: translate(0, -50%);
          }
        }

        button {
          width: 100%;
          padding: 6.19468px 9.29202px;

          &.btn-success {
            background: #007937;
            border-color: #007937;
          }
        }

        label {
          color: #2C3746;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          text-align: left;

          &.success {
            color: #007937;
          }
        }
      }
    }

    .preview-row.passport-preview {
      .small-preview {
        flex-basis: 48%;
      }

      .image-wrapper {
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
          margin-top: 0;
          transform: none;
        }
      }
    }

    .tips-wrapper {
      background: #F6F6F6;
      padding: 10px 5px;
      border-radius: 4px;
      margin-bottom: 20px;

      .tips-title {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        text-align: left;
        color: #E02020;
        margin: 0;
      }
    }

    .submit-wrapper {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #FFFFFFA3;
      padding: 16px 10px;
    }
  }

  // Capture type dialog component
  .capture-type-dialog-wrapper {
    background: white;
    width: 100%;
    height: 100%;

    #closeDialogBtn {
      position: absolute;
      right: 15px;
      top: 160px;
      padding: 0.4rem 0.75rem;
      background: none;
      border: none;
      font-size: 20px;
      z-index: 950;
    }

    .capture-type-dialog-content {
      top: 45%;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      width: 100%;
      z-index: 950;

      .info {
        text-align: center;
        font-size: 15px;
      }

      .btns-wrapper {
        display: flex;
        justify-content: space-evenly;
      }
    }
  }

  // Camera permissions dialog component
  .camera-permission-dialog-wrapper {
    background: white;
    width: 100%;
    height: 100%;

    #closeDialogBtn {
      position: absolute;
      right: 15px;
      top: 160px;
      padding: 0.4rem 0.75rem;
      background: none;
      border: none;
      font-size: 20px;
      z-index: 950;
    }

    .camera-permission-dialog-content {
      top: 45%;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      width: 100%;
      z-index: 950;

      .info {
        text-align: center;
        font-size: 15px;
        padding: 0 30px;
      }

      .btns-wrapper {
        display: flex;
        justify-content: space-evenly;
      }
    }
  }

  .card {
    margin-bottom: 16px;
    margin-top: -20px;
    font-family: Poppins;
  }

  #vid_mobile_main_card {
    margin: 0;
    background: #fff;
  }

  // Carousel
  #carouselParent {
    width: 100%;
    overflow: hidden;
    position: relative;

    #carouselContainer {
      width: auto;

      .owl-stage {
        display: flex;
        align-items: flex-end;
      }

      .owl-dot span {
        width: 7px;
        height: 7px;
      }

      .carouselItem {
        .itemTitle {
          font-weight: 900;
          color: #a61414;
        }

        .itemDescription {
          height: 65px;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          color: #444444;
          margin-top: 15px;
        }

        .itemImg {
          width: 100%;
          position: relative;
        }

        .itemStop {
          width: 25px;
          height: 25px;
          background: #e02020;
          position: absolute;
          z-index: 10;
          bottom: 10px;
          left: 50%;
          transform: translate(-50%, 0);
          color: white;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
        }
      }
    }
  }

  #closeCaptureBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 0.4rem 0.75rem;
  }

  .picture-header {
    z-index: 3000;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100px;
    left: 0;
    background: #2e2e2e;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;

    &#captureHeader {
      position: fixed;
    }

    #headerText {
      font-size: 14px;
      color: white;
      text-align: center;
      padding: 0 50px;
    }
  }

  #previewCaptureCard {
    background: black;
    top: -$menuHeight;
    position: absolute;
    width: 100%;
    z-index: 20;
    padding: 0;
    height: calc(100vh - #{$headerHeight});
    overflow: hidden;

    #previewImage {
      width: 90%;
      margin-left: 5%;
    }
  }
}

@media screen and (min-width: 550px) {
  #vid_mobile_main_card {
    justify-content: center;

    .card-body {
      flex: 0;

      &#previewCaptureCard {
        flex: 1;
        height: 100vh;
        background: black;
      }
    }

    .id-instructions {
      .preview-row {
        .small-preview {
          .image-wrapper {
            height: 150px;
          }
        }
      }
    }
  }

  .picture-header {
    position: absolute;
  }
}

// CSS for redesign mobile VID Screen

.header-image-box {
  background-image: url("../../images/round-bck.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  margin-top: 0px;
}

.header-image {
  width: 100%;
}

.alert-text {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: #444444;
}

.sure-text {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  color: #495057;
  line-height: 18px;
}

.alert-box-sure {
  background-color: #EFEFEF;
  border-radius: 5px;
  padding: 9px 2px;
  margin-top: 5px;

  .text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #444444;
    line-height: 21px;
  }
}

.input-vid {
  border-bottom: 1px solid #556EE6;
}

.start-btn-mobile {
  background-color: #556EE6 !important;
  width: 335px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 50px;
}

.close-icon {
  font-size: 26px;
  margin-left: 10px;
}

.scan-btn-mobile {
  background-color: #556EE6 !important;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
}

.submit-btn-mobile {
  background-color: #556EE6 !important;
  font-family: Poppins;
  font-weight: 700;
  font-size: 16px;
}

.pending-review-card {
  margin-top: -45px
}

.title-card-mobile {
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 500;
  color: #2C3746;
}

.card-description {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  color: #2C3746;
  margin-bottom: 5px
}

.header-image-box-finish {
  background-image: url("../../images/desktop/finish-screen-banner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  margin-top: 0px;
  height: 100px
}