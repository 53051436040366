/********** DOC VIEWER **********/

.pdfwrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
}

.doc-viewer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding-bottom: 80px; // to account for instructions bar
}

.doc-viewer-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px; // half the size of the loader
}

.doc-viewer-fs-wrapper {
  height: 100%;
}

.doc-viewer-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #b8bfc4;
  padding: 24px;
}

// Doc Viewer Header

.doc-viewer-header {
  flex: 0 0 auto;
  padding: 15px 11px;
  background-color: #333639;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.24);
  display: flex;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;

  .btn {
    font-size: 24px;
    color: inherit;
    line-height: 1;
    padding-left: 6px;
    padding-right: 6px;

    &:focus {
      box-shadow: none;
    }

    &.disabled,
    &:disabled {
      opacity: 0.4;
    }
  }
}

.doc-viewer-header-col {
  flex: 1 1 auto;
}

.doc-viewer-title {
  margin: 0;
  font-size: inherit;
  display: inline-block;
  vertical-align: middle;
  color: inherit;
}

.doc-viewer-page-nav,
.doc-viewer-zoom {
  display: inline-block;
  vertical-align: middle;
}

.doc-viewer-header-input {
  display: inline-block;
  vertical-align: middle;
  padding: 3px 7px 0;
  height: 20px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 1px;
  font-size: inherit;
  font-weight: inherit;
  border: none;
  color: inherit;
  line-height: 19px;
  margin-top: -1px;

  &:focus,
  &[readonly] {
    background: rgba(0, 0, 0, 0.8);
    color: inherit;
  }
}

.doc-viewer-page-input {
  width: 35px;
  margin-right: 4px;
  text-align: right;
}

.doc-viewer-zoom-input {
  width: 52px;
  text-align: right;
  padding-right: 20px;
  margin-left: 6px;
}

.doc-viewer-zoom-percent {
  display: inline-block;
  margin: 1px 6px 0 -18px;
  font-size: 15px;
  vertical-align: middle;
  padding-right: 6px;
}

.doc-viewer-header-sep {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  border-right: 1px solid #717375;
  margin: 0 6px;
}

// Doc Viewer Body

.doc-viewer-body {
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  margin-top: 12px;
  overflow: auto; // important so child overflow works
}

// Doc Viewer Content

.doc-viewer-content {
  flex: 1 1 100%;
  overflow-y: auto;
}

.doc-viewer-page-list-wrapper {
  margin: 0 auto; // so it centers when zoom < 100
  overflow: hidden; // hide scrollbars
}

.doc-viewer-page-list {
  transform-origin: left top;
}

.doc-viewer-page {
  text-align: center;

  &:not(:last-child) {
    padding-bottom: 24px;
  }
}

.doc-viewer-page-content {
  position: relative; // also important for calculating child offsetTop

  &.loaded {
    background-color: white;
  }

  .spinner-chase {
    margin: 20px auto;
  }
}

.doc-viewer-page-image {
  width: 100%;
  height: auto;
}

.doc-viewer-drop-target {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; // fields dropped partially outside must not generate scroll
}

// Doc Viewer LeftBar

.doc-viewer-left-bar {
  flex: 0 0 280px;
  height: 100%;
  overflow-y: auto;
  background-color: #2c2f33;
  box-shadow: inset -1px 2px 8px rgba(0, 0, 0, 0.16);
  display: none;
  margin-right: 12px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;

  &.open {
    display: block;
  }
}

.doc-viewer-page-thumb {
  margin: 40px 0;
  text-align: center;

  &.loaded {
    .doc-viewer-page-image {
      background-color: white;
      max-width: 70%;
      border: 6px solid #2c2f33; // parent bg color
      cursor: pointer;
    }
  }

  &.active {
    .doc-viewer-page-image {
      border-color: #93b3f2;
    }
  }
}

.doc-viewer-page-num {
  margin-top: 16px;
}

// Doc Viewer Field

.doc-viewer-field {
  background-color: rgba(0, 183, 140, 0.6);
  border-radius: 32px;
  color: #000000;

  &.signer-type-1.signer-position-1 {
    background-color: rgba(130, 149, 249, 0.6);
  }

  &.signer-type-1.signer-position-2 {
    background-color: rgba(0, 130, 246, 0.6);
  }
}

.doc-viewer-field-added.is-signable {
  cursor: pointer;
}

.doc-viewer-field-added.has-signature {
  background-color: rgba(102, 210, 185, 0.25);
  border: 2px solid rgba(102, 210, 185, 1);

  &.signer-type-1.signer-position-1 {
    background-color: rgba(170, 182, 242, 0.25);
    border-color: rgba(170, 182, 242, 1);
  }

  &.signer-type-1.signer-position-2 {
    background-color: rgba(80, 165, 241, 0.25);
    border-color: rgba(80, 165, 241, 1);
  }
}

.doc-viewer-field-signed {
  border-radius: 0;
  background-color: transparent !important;
}

.doc-viewer-field-body {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 8px 8px 8px 16px;
}

.doc-viewer-field-signed .doc-viewer-field-body {
  padding: 0;
}

.doc-viewer-field-icon {
  flex: 0 0 32px;
  margin-right: 12px;
}

.doc-viewer-field-content {
  flex: 1 1 100%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // field border radius - field padding
  margin-right: 24px;
}

.doc-viewer-field-signed .doc-viewer-field-content {
  margin-right: 0;
}

.doc-viewer-field-label {
  flex: 0 0 auto;
  text-align: left;
  overflow: hidden;
  font-size: 24px;
  line-height: 36px;
}

.doc-viewer-signature-element {
  flex: 1 1 100%;
  overflow: hidden; // needed so child does not expand beyond parent
}

.doc-viewer-signature-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.doc-viewer-signature-metadata {
  flex: 0 0 auto;
  color: $primary;
  border-top: 1px solid $primary;
  margin-top: 5px;
  font-size: 9px;
  line-height: 1;
  padding-top: 2px;
}

// Doc Viewer Footer

.doc-viewer-footer {
  flex: 0 0 auto;
  padding: 24px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  text-align: right;

  .e-sign-doc-info {
    position: absolute;
    margin: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    display: flex;
    align-items: center;
    text-align: left;

    .arrow-icon {
      margin-left: 7px;
      color: #2b69b8;
    }

    .info-text {
      font-weight: 400;
      font-size: 20px;
      letter-spacing: -0.354046px;
    }

    .info-icon {
      padding-right: 20px;
      font-size: 25px;
      color: #2b69b8;
    }
  }
}

// Doc Viewer Sign Modal

.doc-viewer-sign-modal {
  .nav-tabs {
    margin: 0 auto;
  }

  .modal-header {
    position: relative;
  }

  .close {
    top: 5px;
  }
}

.doc-viewer-sign-pad {
  position: relative;

  .hint {
    font-family: "Satisfy", cursive;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    transform: translate(-50%, -50%);
    opacity: 0.3;
    white-space: nowrap;
  }

  .clear-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  .sign-canvas {
    position: relative;
    width: 100%;
    height: 250px;
    display: block;
  }
}

.doc-viewer-sign-text {
  position: relative;
  padding: 3rem;

  .sign-text-field {
    font-family: "Satisfy", cursive;
    font-size: 50px;
    text-align: center;
    color: $primary;
  }

  .text-canvas {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }
}

@include media-breakpoint-down(lg) {
  .doc-viewer-layout {
    padding: 0;
  }

  .doc-viewer-header {
    display: none;
  }

  .doc-viewer-body {
    margin-top: 0;
  }

  .doc-viewer-left-bar,
  .doc-viewer-left-bar.open {
    display: none;
    margin-right: 0;
  }

  .doc-viewer-footer {
    margin: 12px 0 0;
    padding: 0;

    .btn {
      width: 100%;
    }

    .e-sign-doc-info {
      display: none;
    }
  }

  .doc-viewer-sign-text {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .doc-viewer-sign-modal .modal-footer {
    padding: 0;

    .btn {
      flex: 0 0 50%;
      margin: 0;
      background: transparent;
      border: none;
      text-transform: uppercase;
      font-size: 17px;

      &.btn-primary {
        color: $primary;
        border-right: 1px solid #eff2f7;
        font-family: "Poppins"
      }

      &.btn-secondary {
        color: $secondary !important;
      }
    }
  }
}