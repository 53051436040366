#signatures.desktop {
  .sig-column {
    width: 800px;

    .sig-card {
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      align-items: center;
      margin-bottom: 70px;

      .esign-header {
        background: url("/assets/images/signature-background.png") no-repeat;
        height: 280px;
        width: 100%;
        background-size: contain;
        background-position: center;
        position: relative;
        margin-top: 40px;
      }

      .inksign-header {
        background: url("/assets/images/round-bck.svg") no-repeat;
        height: 280px;
        width: 100%;
        background-size: contain;
        background-position: center;
        position: relative;
      }

      .sig-desktop-body {
        flex: 0;
        width: 685px;
        background: #ffffff;
        box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
        border-radius: 4px;
        margin-top: -40px;
        z-index: 10;
        padding: 30px 100px 40px 100px;
        position: relative;

        .instr-parent {
          margin-top: 30px;
          padding: 0 55px;
        }

        .btns-row {
          margin-top: 30px;
          padding: 0px 60px;
        }
      }

      .signature-image-banner {
        margin: 0 auto;
        display: block;
      }

      .start-signature-btn {
        width: 160px;
      }
    }
  }
}