$border-color: #CED4F2;

.appointment-card-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  font-family: 'Poppins';
  color: #2C3746;
}

.appointment-field label {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  color: #495057;
  font-family: "Poppins";
  float: left;
  margin-top: 0px;
}

.appointment-buttons .btn {
  padding: 0.7rem 0.75rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.06px;
  font-family: "Poppins";
  margin-top: 50px
}

.appointment-result {
  padding-top: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  h5 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #2C3746;
    font-family: "Poppins"
  }

  h6 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: "Poppins"
  }

  .box {
    padding: 15px;
    border: 1px solid $border-color;
    border-radius: 4px;
    width: 50%;
    font-family: Poppins;
    font-size: 14px;
    line-height: 24px;
    color: $gray-700;

    .appointment-date {
      max-width: 135px;
      font-family: Poppins;
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
    }
  }
}

.pending-icon {
  width: 105.38px;
  margin-top: 30px;
}

.contact-btn {
  font-size: 14px;
  font-family: "Poppins";
  width: 480px;
}

.pending-appointment-paragraph {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: 22px;
  color: #2C3746;
  text-align: center;
}

.footer-box-text {
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins";
  line-height: 22px;
  color: #2C3746;
  text-align: center;
}


.card-parent {
  max-width: 685px;
  margin: 0 auto;
}

.card-parent-mobile {
  margin-top: -30px;
  margin-right: 20px;
  margin-left: 20px;
}

.appointment-parent-box {
  text-align: center;
  padding: 50px 30px 10px 30px;
}

.appointment-buttons {
  max-width: 340px;
  margin: 0 auto;
}

.banner-image-appointment {
  width: 100%;
  height: 100%;
}

.congratulations-title {
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins";
  line-height: 28px;
  color: #2C3746;
  text-align: center;
}

.appointment-title-accepted {
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: 28px;
  color: #2C3746;
  text-align: center;
}

.appointment-time-title {
  font-family: Poppins;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}

.parent-grid-appointment {
  display: flex
}

.header-image-appointment-desktop {
  max-width: 800px;
}

.header-image-appointment-mobile {
  width: 100%;
  height: 100%;
}

.column-box {
  margin-top: -80px;
}

.apppointment-icon {
  width: 100px;
}

.limited-desktop-box {
  max-width: 685px;
  margin: 0 auto;
}

.opacity-btn {
  opacity: 0.6
}

@media (max-width: 767px) {
  .parent-box {
    padding: 20px 10px;
  }

  .email-rightbar {
    margin: 0;
  }

  .parent-grid-appointment {
    display: block;

    .box {
      width: 100%;
      margin-left: 0px !important;
      margin-top: 15px
    }
  }

  .appointment-buttons {
    max-width: 100%;
  }

  .appointment-parent-box {
    text-align: center;
    padding: 20px 0px 0px 0px;
  }

  .appointment-buttons .btn {
    margin-top: 20px
  }

  .apppointment-icon {
    width: 90px;
  }

  .limited-desktop-box {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: -30px;
  }

}

.location-change {
  background: #E9F2FF;
  border-radius: 4px;
  padding: 10px;

  h6 {
    color: #74788D;
    font-size: 13px;
    line-height: 20px;
  }
}