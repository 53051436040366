.conversation-content-image {
  max-width: 150px;
  cursor: pointer;
}

.chat-input-links {
  right: 5px;
}

.chat-input-links li button {
  font-size: 20px;
}

.chat-dropzone {
  position: relative;

  .dropzone {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    min-height: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    z-index: -1;
    opacity: 0;
    transition: opacity .5s;

    .dz-message {
      padding: 0;
    }

    &.is-drag-active {
      z-index: 2;
      border-color: $info;
      opacity: 1;
    }
  }
}

.dz-preview {
  display: flex;
  flex-flow: row wrap;
}

.dz-preview-item {
  position: relative;
  flex: 0 0 45px;
  height: 45px;
  margin-right: 8px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid $gray-400;
  padding: 1px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }

  .dz-upload-progress {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 30%;
    background-color: #ffffff;
  }

  .dz-preview-loader {
    position: absolute;
    bottom: 0;
    right: 1px;
    line-height: 1;
    color: $dark;
    font-size: 14px;
  }
}

.chat-gallery {

  .pswp__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gallery-preview-img {
    max-width: 100vw !important;
    max-height: 100vh;
  }
}

/************* This input font size is used to prevent zoom in when input is focused on mobile devices*************/
input,
textarea {
  @media(max-width: 768px) {
    font-size: 16px !important;
  }
}