#welcome_wrapper.desktop {
  .welcome-column {
    font-size: 16px;
    width: 1110px;
    margin-top: -52px
  }

  .welcome-title {
    font-size: 24px;
    color: #2C3746;
    font-family: Poppins;
    font-weight: 600
  }

  .welcome-desktop-body {
    padding: 35px;
  }

  #codeWrapper {
    margin: auto;
    margin-top: 30px;
    justify-content: center;

    .code_check_result_icon {
      right: 350px;
    }
  }

  #invalidCodeLabel {
    width: 360px;
    margin: auto;
    margin-top: 10px;
    text-align: center;
  }
}

// New CSS for redesign customer flow

.welcom-paragraph {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #2A3042;
  font-family: Poppins;
  margin-bottom: 50px;
}

.resend-section-parent {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  color: #495057;
}

.resend-btn {
  text-decoration: underline;
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #556EE6;
}

.start-btn {
  background-color: #556EE6 !important;
  width: 335px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
}

.welcome-infos {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: #2A3042;
  line-height: 21px;
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  width: 720px;
  margin-top: 30px
}

.info-text {
  margin-left: 15px;
}

.top-body {
  background-image: url("../../images/round-bck.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.welcome-image {
  height: 250px;
}

.code-digit {
  font-size: 28px !important;
  background-color: #fff !important;
  border-radius: 4px !important;
  height: 72px !important;
  font-family: Poppins !important;
  width: 314px;
}

#codeWrapper.valid input {
  border: 1px solid #0f9b16;
}