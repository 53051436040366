#mitekDisplayContainer {
  z-index: 1000;
}

#mitekHintMessage {
  font-weight: bold !important;
  letter-spacing: 0.8px;
  font-size: 15px !important;
  text-align: center !important;
  color: #222222 !important;
  max-width: 400px;
  border: none !important;
  border-radius: 4px !important;
  width: 70%;
}

.integrator.PASSPORT {
  background: url("/assets/images/mitek/ghost_passport_portrait.png") no-repeat;
  background-size: 100% 100%;
  border: none;
  border-radius: 20px;
}
