/******** Documents list ********/

#finance_mobile_wrapper {
  font-size: 14px;
  color: #495057;
  font-family: Poppins;

  #documents_card {
    margin-bottom: 60px;
    padding: 20px;

    .no-documents-message {
      text-align: center;
      font-size: 13px;
    }

    #accordion {
      overflow-y: scroll;
    }

    .accordion-item {
      border: none;
      margin-top: 20px;
      background-color: rgba(43, 105, 184, 0.15);

      &:first-child {
        margin-top: 0;
      }

      .document-item {
        min-height: 59px;
        padding: 10px;

        .document-title {
          font-weight: 500;
          font-size: 14px;
          color: $gray-700;
        }

        .document-description {
          font-weight: 400;
          font-size: 12px;
          color: $gray-700;
        }

        .accord-arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
        }

        .document-badge {
          font-family: "Poppins";
          padding: 4px 8px;
          font-weight: 400;
          font-size: 10px;
          line-height: 1.1;
          margin-bottom: 4px;
        }
      }
    }
  }
}

/********* Top actions row *********/

.documents-actions-row {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .doc-title {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }
}