body {
  &.modal-open {
    overflow-y: hidden;
    // when modal is open, padding-right is 21px by default, but this leaves a white space in the place of the scrollbar, which we want to hide
    padding-right: 0px !important;
  }
}

.container {
  max-width: 100% !important;
}