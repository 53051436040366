#signature_desktop_wrapper {
  font-size: 14px;
  color: #495057;
  position: relative;
  height: max-content;
  min-height: 100vh;

  #contracts_card {
    background: transparent;
    margin-bottom: 0;
    padding-bottom: 5rem;
    min-height: calc(100vh - 210px);

    .how-to-use-row {
      padding: 20px;
      background: white;
      margin-bottom: 20px;
      font-family: "Poppins";

      .title {
        font-weight: 700;
        font-size: 24px;
        color: #1a1a1a;
        letter-spacing: -0.2px;
        margin-bottom: 10px;
      }

      .list {
        padding-left: 0.5rem;
        margin-bottom: 0;

        li {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.1px;
          color: #2c3746;

          .print-icon,
          .msg-icon {
            font-size: 15px;
            margin: 0 2px;
          }

          .bold {
            font-weight: 700;
          }
        }
      }
    }

    .contract-pages-wrapper {
      max-height: calc(100vh - 200px);
      overflow-y: scroll;
      padding: 20px;

      .contract-page {
        flex-basis: 150px;

        #contract-page-capture {
          height: 200px;
        }
      }
    }

    .accordion-item {
      border: none;
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    .contract-item {
      border: 2px solid #dfe9f4;
      min-height: 87px;
      background: linear-gradient(0deg, rgba(43, 105, 184, 0.15), rgba(43, 105, 184, 0.15)), #ffffff;
      border-radius: 5px;
      margin: 0;
      padding: 10px;

      .contract-title {
        font-weight: 500;
        font-size: 14px;
      }

      .contract-desc {
        font-weight: 400;
        font-size: 12px;
      }

      .accord-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
      }

      .accord-print {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        margin-right: 20px;

        .print-btn {
          border: none;
          background: transparent;
        }

        i {
          color: #495057;
        }
      }

      .badges-section {
        display: flex;
        margin: 10px 0;

        .contract-badge {
          font-family: "Poppins";
          padding: 4px 8px;
          font-weight: 400;
          font-size: 10px;
          display: flex;
          line-height: 1.1;
        }
      }

      &.active {
        border: 2px solid #2b69b8;
      }

      .rejected-reason-wrapper.ink-sign {
        position: relative;

        .reason-label {
          color: #f46a6a;
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 5px;
        }

        .rejection-description {
          color: #2c3746;
          font-size: 10px;
          line-height: 12px;
          overflow: hidden;
          position: relative;
          text-overflow: ellipsis;
          margin-bottom: 5px;

          &.more {
            height: auto;

            .see-more-btn {
              display: none;
            }
          }
        }

        .toggle-reasons-btn {
          border: none;
          background: none;
          color: #2b69b8;
          font-size: 10px;
          padding: 0;
          display: block;
        }
      }
    }
  }
}