#signatures {
  .sig-column {
    padding: 0 25px;
    margin-top: -45px;

    .signatures-title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #2c3746;
      line-height: 28px;
      text-align: center;
    }

    .signatures-subtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #2c3746;
      line-height: 20px;
      text-align: center;
    }

    .signatures-hr {
      border: 1px solid #444444;
    }

    .signatures-cat {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    .sign-req {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      background-color: #EFEFEF;
      border-radius: 8px;
      padding: 10px;
      display: flex;
      align-items: center
    }

    #inkBtn {
      padding: 0.7rem 0.6rem;
    }
  }

  .esign-header {
    background: url("/assets/images/signature-background.png") no-repeat;
    width: 100%;
    background-size: contain;
    background-position: center;
    position: relative;
  }

  .inksign-header {
    background: url("/assets/images/round-bck.svg") no-repeat;
    width: 100%;
    background-size: contain;
    background-position: center;
    position: relative;
  }

  .signature-image-banner {
    margin: 0 auto;
    display: block;
  }
}