// Welcome component
#welcome_wrapper {
  margin-top: 30px;

  .welcome-column {
    padding: 0 25px;
    margin-top: -52px;

  }

  .welcome-title {
    font-size: 20px;
    color: #2C3746;
    font-family: Poppins;
    font-weight: 400
  }

  #invalidCodeLabel {
    color: #da2a2a;
    font-size: 12px;
    display: block;
    text-align: center;
  }

  #codeWrapper {
    margin: 25px 5px 15px 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &.invalid {
      .code-digit {
        border: 1px solid #d94d4d;
      }
    }

    &.disabled {
      .code-digit {
        border: 1px solid #0f9b16;
        pointer-events: none;
        opacity: 0.7;
      }
    }

    .code-digit {
      height: 72px;
      background: #fff;
      border-radius: 1px;
      border: 1px solid #c7c7c7;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      width: 275px;
    }

    .code_check_result_icon {
      position: absolute;
      right: 55px;

      i {
        color: #0f9b16;
        font-size: 20px
      }
    }
  }


  #codeWrapper.valid input {
    border: 1px solid #0f9b16;
  }

  .resend-btn-mobile {
    border: none;
    background: transparent;
    font-family: "Poppins" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #556EE6;
    text-decoration: none !important;
    margin-bottom: 25px !important;

    &.disabled {
      color: #2c3746;
    }

    &:active,
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}

// New CSS for redesign customer flow

.welcome-paragraph {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #2A3042;
  font-family: Poppins;
  margin-bottom: 0px
}

.welcome-infos-mobile {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  color: #2A3042;
  line-height: 18px;
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  margin-top: 10px
}

.info-text {
  margin-left: 15px;
}

.top-body {
  background-image: url("../../images/round-bck.svg");
  background-repeat: no-repeat;
  background-position: center
}

.welcome-image {
  height: 200px !important;
}