/********** Signing pdf viewer *******/

.pdfwrapper.e-sign {
  position: relative;
  padding: 20px;
  // 242px = the height of the top part (navigation + title)
  height: calc(100vh - 242px);

  .doc-viewer {
    position: relative;
  }

  .doc-viewer-footer {
    margin: 0;
    padding-top: 15px;
  }
}

/********* Top actions row *********/

.signatures-actions-row {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  #signBackBtn {
    position: absolute;
    left: 0;
    color: #2c3746;
    margin-left: 20px;
    font-size: 18px;
    background: none;
    border: none;
  }

  .sign-title {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }

  #infoBtn {
    position: absolute;
    right: 0;
    color: #2b69b8;
    font-size: 23px;
    margin-right: 20px;
    border: none;
    background: none;
  }
}

/******** Documents list ********/

.contract-item.e-sign-item {
  height: auto !important;

  .contract-description {
    margin: 0;
    padding: 0;
  }

  .rejected-reason-wrapper {
    margin: 0;
    padding: 0;
    margin-top: 8px;

    .reason-label {
      color: #f46a6a;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .rejection-description {
      color: #2c3746;
      font-size: 10px;
      line-height: 12px;
      overflow: hidden;
      position: relative;
      text-overflow: ellipsis;

      &.more {
        height: auto;

        .see-more-btn {
          display: none;
        }
      }

      .toggle-reasons-btn {
        border: none;
        background: none;
        color: #2b69b8;
        font-size: 10px;
        padding: 0;
        margin-left: 3px;
      }
    }
  }
}

.esign-confirm-alert {
  border-radius: 20px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #2c3746 !important;

  h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #2c3746 !important;
    padding-bottom: 10px !important;
  }

  .e-sign-confirm-btn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #2b69b8;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    border-radius: 0;
    padding: 0.8rem 0.75rem;
    width: 100%;
  }

  .e-sign-cancel-btn {
    font-size: 16px;
    color: #2b69b8;
    font-weight: 700;
    padding-bottom: 0;

    .cancel-description {
      font-size: 14px;
      color: #2c3746;
      font-weight: 400;
      padding: 0 30px;
    }
  }
}

/********** Offcanvas ********/

.offcanvas.offcanvas-bottom {
  height: auto !important;

  .offcanvas-header {
    padding-bottom: 0;
  }

  .offcanvas-body {
    padding-top: 0.5rem;
  }

  .how-to-use-row {
    padding: 10px;
    padding-top: 0;
    background: white;
    font-family: "Poppins";

    .title {
      font-weight: 700;
      font-size: 24px;
      color: #1a1a1a;
      letter-spacing: -0.2px;
      margin-bottom: 10px;
      text-align: center;
    }

    .list {
      padding: 0 20px;
      margin-bottom: 0;

      li {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.1px;
        color: #2c3746;

        .print-icon,
        .msg-icon {
          font-size: 15px;
          margin: 0 2px;
        }

        .bold {
          font-weight: 700;
        }
      }
    }
  }
}

/** Style for trancking section on Ink page ***/

.tracking-number-parent {
  border-radius: 5px;
  background-color: rgba(43, 105, 184, 0.15);
  padding: 10px;
  margin-bottom: 10px;
}

.tracking-text {
  color: #495057;
  font-weight: 500;
  font-size: 14px;
  text-align: center
}

.tracking-link {
  background-color: #2B69B833;
  color: #556EE6;
  border-radius: 15px;
  padding: 3px 10px;
  font-size: 13px;
  text-align: center;
  cursor: pointer
}

$close-btn-color: #323232;

.tracking-modal {
  .modal-content {
    padding: 35px 25px;

    .tracking-modal-header {
      .btn-close {
        position: absolute;
        right: 10px;
        top: 28px;
        color: $close-btn-color;
      }

      .tracking-modal-title {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 29px;
      }
    }

    .modal-body {
      padding: 0;

      .table {
        thead {
          background: $gray-100;
        }
      }

      .package-number {
        font-size: 13px;
        line-height: 20px;
      }

      .btn {
        min-width: 140px;
      }
    }
  }
}

.package-number {
  padding: 2px 6px 2px 6px;
  border-radius: 27px;
  background: rgba(43, 105, 184, 0.2);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $blue;
}