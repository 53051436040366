.e-sign-row {
  height: auto;
  min-height: calc(100vh - 210px); // to account for header, footer and instructions
  padding-bottom: 10px;
}

#contracts_card.desktop.e-sign {
  padding-bottom: 1rem !important;

  #contracts_card_body {
    padding-bottom: 70px; // to account for footer
  }

  .contracts-row {
    justify-content: center;
  }

  .contract-pages-wrapper {
    padding-bottom: 15px;
  }
}

.tracking-number-parent {
  border-radius: 5px;
  background-color: rgba(43, 105, 184, 0.15);
  padding: 10px;
}

.tracking-text {
  color: #495057;
  font-weight: 500;
  font-size: 14px;
  text-align: center
}

.tracking-link {
  background-color: #2B69B833;
  color: #556EE6;
  border-radius: 15px;
  padding: 3px 10px;
  font-size: 13px;
  text-align: center;
  cursor: pointer
}

$close-btn-color: #323232;

.tracking-modal {
  .modal-content {
    padding: 35px 25px;

    .tracking-modal-header {
      .btn-close {
        position: absolute;
        right: 10px;
        top: 28px;
        color: $close-btn-color;
      }

      .tracking-modal-title {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 29px;
      }
    }

    .modal-body {
      padding: 0;

      .table {
        thead {
          background: $gray-100;
        }
      }

      .package-number {
        font-size: 13px;
        line-height: 20px;
      }

      .btn {
        min-width: 140px;
      }
    }
  }
}

.package-number {
  padding: 2px 6px 2px 6px;
  border-radius: 27px;
  background: rgba(43, 105, 184, 0.2);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $blue;
}