#messages_view_mobile_wrapper {
  // height: 100%;

  input[type='text'] {
    font-size: 16px; // to avoid Safari zooming on the element
  }

  .chat-header {
    position: fixed;
    width: 100%;
    height: 90px;
    background-color: white;
    z-index: 999;

    h5 {
      font-weight: 600;
      font-size: 17px;
      margin: 17px 0 4px 6px;
    }

    .back-btn {
      font-size: 40px;
    }
  }

  .chat-conversation {
    height: 100%;
    padding: 90px 6px;

    .chat-item {
      clear: both;
    }
  }

  .chat-send {
    width: 43px;
    height: 43px;
  }

  .chat-input-section {
    width: 100%;
    position: fixed;
    background-color: white;
    bottom: 0;
  }

  .clear-float {
    clear: both;
  }

  .dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $primary;
    color: $primary;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $primary;
    color: $primary;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $primary;
    color: $primary;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: $primary;
    }

    50%,
    100% {
      background-color: rgba(85, 110, 230, 0.2);
    }
  }
}

#messages_menu_mobile_wrapper {
  .user-avatar {
    border-color: #f5f4f8 !important; // fix bug on Safari
  }
}

#mobile_chat_modal {
  .modal-header {
    padding: 16px 0;

    .close-btn {
      i {
        font-size: 32px;
      }
    }
  }
}

.white-backdrop {
  background-color: white;
  opacity: 1 !important;
}