/********** Finish component *****/

.finish-column {
  padding: 0 30px;
  margin-top: -30px !important;

  .verif-icon {
    width: 85px;
  }
}

.return-label-mobile {
  font-family: "Poppins";
  font-style: normal;
  text-align: justify;
}